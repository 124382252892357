@import '~react-multi-carousel/lib/styles.css';

.components {
    &__carousel {
        position: relative;
        height: 265px;
        padding: 0 !important;
        &-item {
            border-radius: 10px;
            border: 1px solid #A19A9A;
            overflow: hidden;
            img {
                width: 100%;
                height: 266px;
                object-fit: contain;
            }
        }
        &_arrow {
            position: absolute;
            top: 45%;
            z-index: 1 !important;
            cursor: pointer;
            &-left {
                left: 1px;
            }
            &-right {
                right: 1px;
            }
        }
        &-default-image {
            width: 100%;
            height: 265px;
            object-fit: cover;
            border-radius: 10px;
        }
        &_item_counter {
            position: absolute;
            right: 8px;
            bottom: 8px;
            z-index: 1;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            background: #FFFFFF;
            border-radius: 5px;
            padding: 3px 6px;
        }
    }
}
