.btn-add-one_time_password {
    width: 200px;
    height: 40px;
    background: #36a40f;
    border: 1px solid #36a40f;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    text-decoration: none;
}

.button-detail {
    min-width: 0px;
    height: 25px;
    font-size: 12px;
    font-weight: 400;
    padding: 0 5px;
    border: 1px solid #666666;
    border-radius: 5px;
    white-space: nowrap;
}

.col-action {
    width: 75px;
}
.label-btn-detail {
    margin-top: 3px;
}
