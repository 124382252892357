.container {
    background: #ffffff;
    border: 1px solid #d9dbdf;
    box-shadow: 0px 4px 10px 0px #00000014;
    border-radius: 10px;

    .header {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 20px 30px 15px;
        border-bottom: 1px solid #c0c0c0;

        .header-image {
            width: 30px;
            height: 30px;
        }

        .header-text {
            color: var(--color-title);
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
        }
    }

    .content {
        padding: 30px;
    }
}
