.information {
    height: 160px;
    display: flex;
    justify-content: center;
    gap: 40px;
    align-items: center;
    background: linear-gradient(102.42deg, #8479ff 4.91%, #3e85ef 98.51%);
    border-radius: 10px;

    .number {
        font-weight: 900;
        font-size: 45px;
        line-height: 54px;
    }

    .text {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
    }
}

.sub-information {
    .information-row {
        display: flex;
        gap: 20px;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .number {
        font-size: 35px;
        line-height: 42px;
    }
}

.border-door-section {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

//container
.card-row {
    display: flex;
    flex-wrap: wrap;
}

.card-border {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 1rem;
    padding: 4px 20px;
    gap: 10px;
    border: 1px solid var(--color-border);
    border-radius: 7px;
}

.list-notice {
    color: #333333;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 10px;
    border-bottom: 1px solid #cfcfcf;
    cursor: pointer;

    .item-notice {
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis !important;
        width: 100%;
        white-space: nowrap !important;
    }
}

.list-notice:last-child {
    border-bottom: none;
}

.item-info {
    padding: 20px 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border: 2px dashed #cfcfcf;
    border-radius: 10px;
    flex: 1;

    .item-col {
        gap: 10px;
    }

    .item-col-border {
        border-right: 1px solid #d5d5d5;
    }

    .count-number {
        color: #584ed1;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
    }

    .type-info {
        font-weight: 700;
        line-height: 17px;
        color: #666666;
    }
}

.btnUser {
    color: #333333;
    background: #f1f1f1;
    min-width: 182px;
    min-height: 44px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    padding: 10px 20px;
    cursor: pointer;
}
.btnActive {
    background: #211c61;
    color: #ffffff;
}

.boxBtn {
    border-bottom: 1px solid #999999;
}
.boxNotification {
    color: #666666;
    background: #ffffff;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
}
.active-boxNotification {
    background: #e6e5f7;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #000000;
}
.link {
    &:hover {
        cursor: pointer;
    }
    height: 20px;
    // border-bottom: 1px solid #0038ff;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #0038ff;
}
.content {
    font-size: 20px;
    line-height: 24px;
    color: #211c61;
}
.img-calendar {
    margin-bottom: 2px;
}
.text_date_detail {
    font-size: 12px;
    color: #333333;
}
.text_content_detail {
    color: #333333;
}

.reject__reason {
    display: flex;
    flex-direction: column;
    background-color: #fff3f3;
    border: 1px solid #ff0000;
    border-radius: 8px;
    padding: 10px;
    white-space: pre-wrap;
}

.notification__container {
    padding: 30px;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    height: 600px;
    display: flex;
    flex-direction: column;
}

.list__notification {
    max-height: 82%;
    overflow-y: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.notification__item {
    cursor: pointer;
    color: #666666;
    background: #ffffff;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
}
.notification--active {
    color: #000000;
    background: #e6e5f7;
}
.notification__detail {
    height: 100%;
    overflow-y: auto;
}
