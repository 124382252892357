@import '~react-toastify/dist/ReactToastify.css';
.components {
    &__toast {
        .Toastify {
            &__toast {
                margin-bottom: 0px !important;
                min-height: unset !important;
                padding: unset !important;
                box-shadow: 2px 2px 4px rgba(34, 34, 34, 0.5) !important;
                &-body {
                    padding: 8px 16px 8px 18px !important;
                    line-height: 24px !important;
                }
                &-container {
                    top: 24px !important;
                    padding: unset !important;
                }
                &-theme--light {
                    color: #000 !important;
                }
            }
        }
    }
}
