.wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    // min-width: 50px;
    width: 100%;
    word-break: keep-all;
    padding: 0 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    background-color: var(--white);
    border: 1px solid transparent;
    user-select: none;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

//Login Form
.login {
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.2;
    color: #fff;
    background-color: var(--btn-color);
    border-color: 1px solid white;
    &:hover {
        color: #fff;
        background-color: var(--btn-color-hover);
        border-color: var(--btn-color-hover);
    }
}

.signup {
    width: 100%;
    font-size: 1rem;
    border: 1px solid transparent;
    padding: 11px 10px 10px;
    background: #ffffff;
    border: 1px solid #4d4790;
    border-radius: 5px;
    font-size: 16px;
    line-height: 19px;
    &:hover {
        color: #fff;
        background-color: var(--btn-color);
        border-color: var(--black);
    }
}

.search,
.create,
.filter {
    display: flex;
}

.search,
.edit,
.create,
.filter {
    &:hover {
        color: #fff;
        background-color: var(--btn-color-hover);
        border-color: var(--btn-color-hover);
    }
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: #4d4790;
    border: 1px solid #4d4790;
    color: var(--white);
    border-radius: 5px;
}

//delete
.del {
    color: #333333;
    border: 1px solid #333333;
}
.warning {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 10px 10px;
    background: #ff0000;
    border: 1px solid #36a40f;
    color: var(--white);
    border-radius: 5px;
}

.filter {
    min-width: unset !important;
    width: 100%;
}

.add {
    width: 200px;
    height: 40px;
    background: #36a40f;
    border: 1px solid #36a40f;
    border-radius: 5px;
    padding: 11px 10px 10px;
    gap: 10px;
}

.add > span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
}

.top.wrapper {
    position: fixed;
    z-index: 1000;
    height: 45px;
    right: 20px;
    bottom: 11px;
    background: #444444;
    border: 1px solid #d9dbdf;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    width: unset !important;
}

.top span {
    font-weight: 700;
    font-size: 12px;
    line-height: 14.52px;
    text-align: center;
    color: #ffffff;
    align-items: center;
    padding-top: 0.25rem;
}

.image-button-style {
    width: 25px;
    height: 25px;
    align-items: center;
    margin-right: 15px;
}

.button-non-min-width {
    min-width: unset !important;
    width: 100%;
}

.danger {
    background-color: #fff;
    color: red;
    border-color: red;
}
