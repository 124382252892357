.select_drop {
    display: flex;
    height: 25px;
    align-items: 'center';
    padding-left: '8px';
    border-radius: '4px';
    font-size: '14px';
    cursor: pointer;
    color: #b8b6b4;
    width: 100%;
    justify-content: space-between;
}
.select_list {
    margin-top: 10px;
    margin-left: -9px;
    position: absolute;
    z-index: 1000 !important;
    width: calc(100% + 1rem);
    border: 1px solid #b8b6b4;
    border-radius: 4px;
    background-color: white;
    padding: 0 15px;
}
.select_listItem {
    margin: '10px';
    padding: '0px 15px';
    text-align: 'left';
    cursor: 'pointer';
}
// .item_selected {
//     // background-color: #c8deff;
// }

.form-check-label {
    pointer-events: none;
}

.gl-border {
    border-color: var(--btn-color) !important;
}

.dropdown_input {
    margin-right: 8px;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
}
