.btn-add-keybox {
    width: 200px;
    height: 40px;
    background: #36a40f;
    border: 1px solid #36a40f;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    text-decoration: none;
}

.pr-22 {
    padding-right: 22px !important;
}

.styles-room {
    // width: 300px;
    // overflow: hidden;
    // text-overflow: ellipsis;
}
