.element {
  border: 1px solid #DADADA;
  border-radius: 7px;
  padding: 20px;
  flex: 1 1 230px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 20px 0px #0000001A;
  min-width: 230px;
  max-width: 245px;
}
.separation {
  border-bottom: 1px solid #D9D9D9;
  margin-top: 20px;
  margin-bottom: 20px;
}
.f-1 {
  flex: 1;
}
.percent_bar{
  height: 12px;
  width: 100%;
  border-radius: 7px;
  background: #F3F3F3;
  margin-top: 5px;
  margin-bottom: 5px;
}
.percent {
  width: 1%;
  height: 12px;
  border-radius: 7px;
  background: #38C905;
  padding: 0.01em 6px;
}
.option_content{
  font-size: 12px;
}
.word-spacing {
  word-spacing: 100vw; 
}

@media screen and (max-width: 1669px) {
  .element {
    max-width: 245px;
  }
}