.button-detail {
    min-width: 0px;
    height: 25px;
    font-size: 14px;
    font-weight: 400;
    padding: 0 5px;
    border: 1px solid #666666;
    border-radius: 5px;
    white-space: nowrap;
}

.button-detail span {
    font-weight: normal;
}

.asset-image {
    padding: 14.5856px;
    gap: 2.39px;

    width: 59.34px;
    height: 59.34px;

    background: #f7f7f7;
    border-radius: 4.13459px;
}

.col-action {
    width: 75px;
}
.btnResetPassword {
    border: none !important;
    width: 200px;
}
.btnCancel {
    background-color: #4d4790;
    color: #ffffff;
}
.label_input {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
