.body {
    padding: 20px !important;
}
.content {
    padding: 30px;
    max-height: 100%;
    border: 1px solid #d0d0d0;
    border-radius: 10px;
}
.container {
    //max-height: calc(100vh - 235px);
    overflow: hidden;
    width: 100%;
}
.ol-y {
    overflow-y: auto;
}
.mx-height-29 {
    max-height: calc(100vh - (-66px)) !important;
}

.f-1 {
    flex: 1;
}
.btn-table {
    font-weight: 400 !important;
    width: 55px;
    min-width: 55px;
    padding: 5px;
    height: 25px;
    font-size: 12px;
}
.btn-table-detail {
    color: #333333;
    height: 25px;
    border: 1px solid #000000;
}
.view__table {
    // max-height: calc(100vh - 430px);
    min-width: 480px;
    // overflow-y: auto;
    // overflow-x: hidden;
}
.table {
    color: #333333 !important;
    position: relative;
    margin-bottom: 0 !important;
    thead {
        th {
            position: sticky;
            top: -1px;
            background: #f0f0f0;
            white-space: nowrap;
            z-index: 1;
        }
    }
    tbody {
        font-size: 12px;
        width: 100%;
    }
}

.w__75 {
    width: 75px;
    min-width: 75px;
}
.w__198 {
    width: 198px;
    min-width: 198px;
}
.w__125 {
    width: 125px;
    min-width: 125px;
}
.mw__300 {
    max-width: 300px;
    width: 100%;
    min-width: 100px;
}
.txt-overflow__ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 10px;
}
.btn-add-survey {
    width: 170px;
    height: 38px;
    background: #36a40f;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    text-decoration: none;
}
.btn-submit {
    width: 170px;
    height: 38px;
}
.txt-note {
    margin-top: 10px;
    color: #ff0000;
    font-size: 12px;
}
.title-survey-public {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 10px;
}
.survey-public-content {
    border: 2px solid #d9d9d9;
    border-radius: 5px;
    padding: 15px;
}
.btn-delete {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    cursor: pointer;
    object-fit: none;
}
.checkbox-custome {
    .row {
        display: flex !important;
        flex-direction: column !important;
    }
}
.text-public {
    color: #51a60e;
}
.text-private {
    color: #ff0000;
}
.modal-detail {
    padding: 24px;
    padding-top: 9px;
    padding-bottom: 0px;
}
.modal-content {
    border: 1px solid #d0d0d0;
    border-radius: 10px;
    padding: 20px;
}
.opacity--0 {
    opacity: 0;
}
.cursor__default {
    cursor: default;
}
@media only screen and (max-width: 1518px) {
    .mx-height-29 {
        max-height: calc(100vh - (-377px)) !important;
    }
}
