.button-detail {
    min-width: 0px;
    height: 25px;
    font-size: 14px;
    font-weight: 400;
    padding: 0 5px;
    border: 1px solid #666666;
    border-radius: 5px;
    white-space: nowrap;
}
.modal-detail {
    padding: 24px;
    padding-top: 9px;
    padding-bottom: 0px;
}
.modal-content{
    border: 2px solid #DDDDE2;
    border-radius: 10px;
    padding: 15px;   
}
.f-1{
    flex: 1;
}
.option__separation{
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #DDDDE2;
}