.content-container {
    border: 1px #d0d0d0;
}

.button-change-photo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 10px 10px;
    gap: 10px;
    min-width: unset !important;
    width: 150px;
    height: 40px;

    background: #ffffff;
    border: 1px solid #333333;
    border-radius: 5px;
}

.button-change-photo > span {
    font-weight: normal;
    color: #333333;
    font-size: 16px;
    line-height: 19px;
}

.image-wrapper {
    width: 150px;
    height: 150px;

    background: #f7f7f7;
    border-radius: 10.375px;
}

.image-register {
    padding: 36.6px;
    gap: 6px;

    width: 140px;
    height: 140px;

    background: #f7f7f7;
    border-radius: 10.375px;
}

.divider {
    width: 100%;
    height: 0px;
    border-top: 1px solid #cccccc;
}

.text-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}

.button-add {
    padding: 11px 10px 10px;
    gap: 10px;
    width: 100%;
    height: 38px;
    background: #36a40f;
    border: 1px solid #36a40f;
    border-radius: 5px;
    min-width: unset !important;
}

.button-add > span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
}
.text_notification{
    white-space: break-spaces;
}
.content-section-border {
    border: 1px solid #d9dbdf;
}

.button-add-asset {
    padding: 11px 10px 10px;
    gap: 10px;
    width: 390px !important;
    height: 40px;
    background: #4d4790;
    border-radius: 5px;
}

.button-setting {
    padding: 5px;
    gap: 10px;
    width: 38px;
    height: 27px;
    background: #ffffff;
    border: 1px solid #666666;
    border-radius: 5px;
    min-width: unset !important;
}

.button-setting > span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    color: #333333;
}

.button-add-image {
    width: 100%;
    height: 40px;
    background: #ffffff;
    border: 1px solid #333333;
    border-radius: 5px;
    min-width: unset !important;
}

.height-content {
    height: 100% !important;
}

.image-table {
    padding: 12.2893px;
    gap: 2.01px;
    width: 50px;
    height: 50px;
    background: #f7f7f7;
    border-radius: 3.48366px;
}

.button-option {
    gap: 10px;
    width: 250px;
    height: 40px;
    background: #34827d;
    border: 1px solid #34827d;
    border-radius: 5px;
}

.button-option > span {
    width: 230px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
}

.button-bottom {
    margin-top: 20px !important;
    width: 230px !important;
}

.content {
    padding: 20px 29px;
    gap: 30px;
    max-height: 100%;
    border: 1px solid #d0d0d0;
    color: #333333 !important;
}
//slide
.img_close {
    position: absolute;
    top: -10px;
    right: -8px;
    cursor: pointer;
}
.company-image {
    width: 353px;
    height: 265px;
}
.img_edit {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 3px;
}
.slide {
    &_container {
        margin-top: 15px;
        display: flex;
        align-items: center;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        height: 100px;
        background-color: #f6f6f6;
    }
    &_button {
        cursor: pointer;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &--disable {
            cursor: default;
        }
    }
    &_list {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 12px;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        margin: 0 auto;
    }
    &_btn_upload {
        flex: 0 0 auto;
        margin: 0 0.8rem;
    }
    &_img {
        width: 150px;
        object-fit: cover;
        background: #f7f7f7;
        border-radius: 5px;
        border: 1px solid #a29a9a;
        cursor: pointer;

        scroll-snap-align: start;
        transform-origin: start start;
        transform: scale(1);
        transition: transform 0.5s;
        height: 110px;

        &--active {
            border: 2px solid #4d4790;
        }
    }
    &_img_edit {
        background-color: white;
        position: relative;
        border-radius: 5px;
        border: 1px solid #a29a9a;
        cursor: pointer;
        scroll-snap-align: start;
        transform-origin: start start;
        transform: scale(1);
        transition: transform 0.5s;
        max-height: 80%;
        flex: 0 0 auto;
        width: 90px;
        height: 67px;
        display: flex;
        align-items: center;
        box-sizing: content-box;

        &--active {
            border: 2px solid #4d4790;
        }
    }
}

.gl-object-fit-contain {
    object-fit: contain;
}

.gl-object-fit-cover {
    object-fit: cover;
}
.img-upload {
    margin-right: 6px;
    width: 24px;
    height: 24px;
}
.btn-address {
    width: 68px;
    height: 40px;
}
.input {
    margin-left: 22px;
    margin-right: 15px;
}
.form-radio{
    margin-left: 34.5%;
}
.text-positive{
    color: #51a60e;
}
.text-danger{
    color: #FF0000;
}
.asset-image {
    gap: 2.39px;
    width: 80px;
    height: 60px;
    border-radius: 3.13529px;
    object-fit: contain;
}
.button {
    :hover {
        background: none !important;
    }
    border: 1px solid black;
    border-radius: 5px;
    width: 170px !important;
    min-width: 140px !important;
    height: 40px;
    color: #333333 !important;
    background: #ffffff !important;
}
.content-popup {
   margin: 4px 24px;
}
.text-noti{
    color: #0376C9;
    text-decoration:underline;
     cursor: pointer;
}
@media only screen and (min-width: 1590px) {
    .button-option {
        margin-right: 1rem!important;
    }
}
