.bases {
    &__text {
        &--red {
            color: #ff0000 !important;
        }
        &--blue {
            color: #4d4790 !important;
        }
        &--link {
            color: #0038ff !important;
        }
        &--label {
            color: #333333 !important;
        }
        &--font {
            @for $i from 0 through 100 {
                &#{$i} {
                    font-size: #{$i}px !important;
                }
            }
        }
        &--ellipsis {
            overflow: hidden !important;
            width: 100%;
            text-overflow: ellipsis;
        }
    }
    &__p {
        &--pointer {
            cursor: pointer;
        }
        &--none {
            cursor: not-allowed;
        }
    }
    &__width {
        @for $i from 0 through 1500 {
            &--#{$i} {
                width: #{$i}px !important;
            }
        }
    }
    &__width-percent {
        @for $i from 1 through 100 {
            &--#{$i} {
                width: #{$i + '%'} !important;
            }
        }
    }
    &__width-min {
        @for $i from 1 through 1000 {
            &--#{$i} {
                min-width: #{$i}px !important;
            }
        }
    }
    &__width-max {
        @for $i from 1 through 1000 {
            &--#{$i} {
                max-width: #{$i}px !important;
            }
        }
    }
    &__width-unset {
        width: unset;
    }
    &__height {
        @for $i from 10 through 1000 {
            &--#{$i} {
                height: #{$i}px !important;
            }
        }
    }
    &__height-percent {
        @for $i from 1 through 100 {
            &--#{$i} {
                height: #{$i + '%'} !important;
            }
        }
    }
    &__height-max {
        @for $i from 10 through 1000 {
            &--#{$i} {
                max-height: #{$i}px !important;
            }
        }
    }
    &__height-max-percent {
        @for $i from 0 through 100 {
            &--#{$i} {
                max-height: #{$i + '%'} !important;
            }
        }
    }
    &__height-max-vh {
        @for $i from 0 through 100 {
            &--#{$i} {
                max-height: #{$i + 'vh'} !important;
            }
        }
    }
    &__height-min {
        @for $i from 10 through 1000 {
            &--#{$i} {
                min-height: #{$i}px !important;
            }
        }
    }
    &__margin {
        @for $i from 0 through 250 {
            &--left#{$i} {
                margin-left: #{$i}px !important;
            }
            &--right#{$i} {
                margin-right: #{$i}px !important;
            }
            &--bottom#{$i} {
                margin-bottom: #{$i}px !important;
            }
            &--top#{$i} {
                margin-top: #{$i}px !important;
            }
            &--vertical#{$i} {
                margin-top: #{$i}px !important;
                margin-bottom: #{$i}px !important;
            }
            &--horizontal#{$i} {
                margin-left: #{$i}px !important;
                margin-right: #{$i}px !important;
            }
            &#{$i} {
                margin: #{$i}px !important;
            }
        }
    }
    &__padding {
        @for $i from 0 through 200 {
            &--left#{$i} {
                padding-left: #{$i}px !important;
            }
            &--right#{$i} {
                padding-right: #{$i}px !important;
            }
            &--bottom#{$i} {
                padding-bottom: #{$i}px !important;
            }
            &--top#{$i} {
                padding-top: #{$i}px !important;
            }
            &--vertical#{$i} {
                padding-top: #{$i}px !important;
                padding-bottom: #{$i}px !important;
            }
            &--horizontal#{$i} {
                padding-left: #{$i}px !important;
                padding-right: #{$i}px !important;
            }
            &#{$i} {
                padding: #{$i}px !important;
            }
        }
    }
    &__table--image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 4px;
    }
    &__border {
        &--red {
            border: 2px solid #ff0000;
        }
        &-red {
            border-color: #ff0000;
        }
        &-blue {
            border-color: #4d4790;
        }
    }
    &__error {
        color: #ff0000;
        font-size: 12px;
    }
    &__right- {
        @for $i from 0 through 100 {
            &#{$i} {
                right: #{$i}px !important;
            }
        }
    }
}
textarea {
    &:focus-visible {
        outline: unset !important;
        border: 1px solid #4d4790 !important;
    }
}

.no_scroll {
    overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}

.text-pre-wrap {
    white-space: pre-wrap;
}
