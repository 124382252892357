.breadcrumb {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #666666;
    &.main {
        color: #211c61 !important;
    }

    &--placeholder {
        color: transparent;
    }
}
