@import 'normalize.css';

:root {
    --black: #000;
    --white: #fff;
    --blue: #8496b0;
    --gray: #d8d8d8;
    --light-gray: #f2f2f2;

    --background-color: #2b2851;
    --color-border: #211c61;
    --btn-color: #4d4790;
    --btn-color-hover: #013b93;

    //text color
    --color-title: #211c61;
    --color-label: #333333;

    //font-weight
    --font-weight-bold: 700;

    --width-open-sidebar: 280px;
    --width-close-sidebar: 0px;

    //Title Spacing
    --container-padding: 1rem;
    --search-top-spacer: 9px;
    --search-button-width: 52px;

    //animation-delay:
    --animate-delay: 800ms;
}

button,
input,
[tabindex] {
    outline: none;
    border: none;
}

input {
    color: var(--color-label) !important;
    font-family: 'Inter' !important;
}

a {
    color: var(--black);
    text-decoration: none;
}

body {
    width: 100%;
    height: 100vh;
    font-family: 'Inter' !important;
    font-size: 14px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* setting scrollbar */
/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #8590a5;
    border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8590a5;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
}

.modal.modal-static .modal-dialog {
    transform: none !important;
}