.col-action {
    width: 190px;
}

.button-detail {
    min-width: 0px;
    height: 25px;
    font-size: 12px;
    font-weight: 400;
    padding: 0 5px;
    border: 1px solid #666666;
    border-radius: 5px;
    white-space: nowrap;
    line-height: 15px;
}

.btn {
    width: 80px;
    height: 25px;
    text-align: center;
    font-size: 12px;
    border-radius: 5px;

    border: 1px solid #666666;
    background: #ffffff;
    color: #333333;

    &__approved {
        background-color: #4fa50c;
        border: none;
        color: #ffffff;
    }
    &__non-approved {
        background-color: #ef5050;
        border: none;
        color: #ffffff;
    }

    &-disable {
        opacity: 1 !important;
    }

    &:disabled {
        opacity: 0.5;
    }
}
