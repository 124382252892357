.container-home {
    width: 100%;
    background-color: #e5e5e5;
    overflow-x: hidden;

    &.loader {
        opacity: 50%;
    }
}

.wrapper {
    margin-left: var(--width-open-sidebar);
    &.sidebar--close {
        margin-left: var(--width-close-sidebar);
    }

    &__body {
        margin-top: 60px;
        .content {
            min-height: calc(100vh - 110px);
            padding: 20px;
        }

        .footer {
            text-align: center !important;
            height: 50px;
            display: flex;
            align-items: center;
            margin-left: var(--container-padding);
            font-family: 'Inter';
            font-size: 13px;
            line-height: 16px;
            color: #999;
            opacity: 0.7;
        }
    }
}
