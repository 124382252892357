.components {
    &__table {
        position: relative;
        z-index: 2;
        &-wrapper {
            border-left: 1.5px solid #dfdfdf;
            border-right: 1.5px solid #dfdfdf;
            overflow: auto !important;
        }
        &-border {
            min-width: 100%;
            border: 1.5px solid #dfdfdf;
            border-collapse: collapse;
            thead {
                height: 38px !important;
                th {
                    position: sticky !important;
                    top: -1px;
                    background-color: #f0f0f0 !important;
                    border: 1.5px solid #dfdfdf;
                    position: relative;
                    line-height: 1;
                    padding: 0 10px;
                    color: #333333;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    white-space: pre !important;
                    z-index: 5;
                    .icon-remove {
                        width: 16px;
                        height: 16px;
                    }
                    &:first-child {
                        border-left: 0;
                    }
                    &:last-child {
                        border-right: 0;
                    }
                }
            }
            tbody {
                //position: relative;
                tr {
                    height: 38px !important;
                    &:nth-child(even) {
                        td {
                            background-color: #f7f7f7;
                        }
                    }
                    &:nth-child(odd) {
                        td {
                            background-color: white;
                        }
                    }
                    td {
                        border: 1.5px solid #dfdfdf;
                        font-size: 12px;
                        padding: 10px;
                        white-space: nowrap !important;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        color: #333333;
                        .td-span {
                            .gl-tooltip {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                width: 100%;
                                white-space: nowrap !important;
                                display: inherit;
                            }
                            a:hover {
                                color: unset;
                            }
                        }
                        img {
                            margin: 10px 0;
                        }
                        &:first-child {
                            border-left: 0;
                        }
                        &:last-child {
                            border-right: 0;
                        }
                    }
                }
            }
        }
        &-link {
            color: #0d6efd;
            text-decoration: underline;
        }
        &--top-table {
            display: flex;
            align-items: center;
        }
        &-btn {
            margin: auto;
            padding: 0;
            img {
                margin-right: 0;
            }
            &_body {
                padding-top: 11px !important;
                padding-bottom: 11px !important;
            }
        }
        &-sorted {
            margin-right: 20px !important;
            display: inline-block !important;
            &-icon {
                cursor: pointer;
                position: absolute;
                top: calc(50% - 7px);
                right: 8px;
                width: 8px;
                height: 14px;
            }
        }
        &-sticky {
            border-collapse: separate;
            border-spacing: 0;
            table-layout: fixed;
            tr {
                td:last-child {
                    position: sticky;
                    right: -1px;
                    z-index: 4;
                }
                th:last-child {
                    position: sticky;
                    right: -1px;
                    z-index: 5;
                }
            }
            &-checkbox {
                position: sticky;
                left: -1px;
                z-index: 6 !important;
            }
        }
        &-no-data {
            color: #333333;
        }
        &-avatar {
            width: 80px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #a29a9a;
            border-radius: 4px;
        }
    }
}

.components {
    &__pagination {
        gap: 10px;
        > span {
            display: inline-flex;
            width: 30px;
            height: 30px;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: #26292f;
            border: 1px solid #666666;
            border-radius: 5px;
            cursor: pointer;

            &.disabled {
                opacity: 0.5;
                cursor: unset;
            }

            &.current {
                color: #fff !important;
                background-color: #4d4790;
                cursor: unset !important;
            }
        }
    }
    &__next_prev_disable {
        cursor: default !important;
        opacity: 0.5;
    }
}

.pagination {
    &_total {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #001aff;
        display: inline-flex;
        align-items: center;
    }
}

.tooltip-inner {
    font-size: 12px;
}

.keybox_text{
    float: right;
    color: #ff0000 ;
}
