.modal-body-border {
    padding: 20px;
    gap: 30px;
    //border: 1px solid #D0D0D0;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}

.header-btn_close {
    background-color: unset;
    top: 10px;
    right: 10px;
    z-index: 10;
}

.header-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #333333;
}

.button-green {
    gap: 10px;
    width: 170px;
    height: 38px;
    background: #34827d;
    border: 1px solid #34827d;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
}
.button {
    gap: 10px;
    width: 230px;
    height: 40px;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}
.btn {
    &:focus {
        border: unset !important;
        box-shadow: unset !important;
    }
}

.body {
    color: #333333;
    line-height: 1.7;
    overflow-x: hidden;
}
.header {
    padding: 0 20px !important;
}

.gl-modal {
    padding: 0 !important;
    > div {
        transform: none !important;
        > div {
            max-height: 95vh;
        }
    }
}

.gl-gap-20 {
    gap: 20px !important;
}

.gl-w-170 {
    width: 170px !important;
}

.modal-fixed {
    max-height: 70vh;
}

.header-fixed {
    position: sticky;
    top: 0;
    background-color: inherit;
    // z-index: 10;
}

.footer-fixed {
    position: sticky;
    bottom: 0;
    background-color: inherit;
    // z-index: 10;
}

.body-fixed {
    max-height: 80%;
    overflow-y: auto;
}

.btn-custom {
    color: #333333;
    background-color: #ffffff;
    border-color: #333333;

    &:hover {
        color: #333333;
        background-color: #ffffff;
        border-color: #333333;
    }
}

.btn-custom-1 {
    background-color: #51a60e;
    border-color: #51a60e;

    &:hover {
        background-color: #51a60e;
        border-color: #51a60e;
    }
}
