.table-section {
    width: 100%;
    border: 1px solid #b1b7c1;
    border-radius: 5px;
}
.image-register {
    object-fit: contain !important;
    width: 250px !important;
    height: 250px !important;
}
.button-delete {
    width: 34px !important;
    height: 25px;
    border: 1px solid red !important;
    color: red;
    font-weight: 400;
    padding: 0 5px;
    border: 1px solid #666666;
    border-radius: 5px;
    white-space: nowrap;
    align-items: center;
}
.add_dropdown {
    width: 120px;
    height: 40px;
    background: #36a40f;
    border: 1px solid #36a40f;
    border-radius: 5px;
    color: #ffffff;
}
.add-img {
    width: 100px !important;
    height: 38px !important;
    font-weight: 400 !important;
    background: #ffffff;
    border: 1px solid #333333;
    border-radius: 9.18421px;
}
.img_close{
  position: absolute;
  top: -10px;
  right: -16px;
  cursor: pointer;
}
.responsive-img{
    width: 267px !important;
    margin-bottom: 10px;
}
.fix_dropdown{
    position: absolute;
    left: 0;
    right: 80px !important;
    width: unset !important;
}
.text{
    color: #333333;
}
@media only screen and (max-width: 1366px) {
    .image-register{
        width: 150px !important;
        height: 150px !important;
    }
    .responsive-img{
        width: 162px !important;
    }
   
}
.item_option{
    text-overflow: ellipsis;
}
