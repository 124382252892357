.input_image {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
}

.input-group-gl {
    min-height: 40px;
    gap: 10px;

    &:has(.gl-input:focus) {
        border: 1px solid #4d4790 !important;
    }
}

.width-47 {
    width: 47% !important;
}

.width-6 {
    width: 6% !important;
}

.error-msg {
    font-size: 14px !important;
    margin-top: 5px;
    text-align: left;
    font-size: 0.8rem;

    .text-overflow {
        white-space: pre-wrap;
        color: #ff0000 !important;
    }

    .error {
        color: #ff0000 !important;
    }
}

.icon-after {
    display: inline-flex;
    position: absolute;
    right: 10px;
    font-size: 17px;
}

.icon-eye {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    font-size: 16px;
    color: #999999;
    background-color: unset;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
}

.input_error {
    border: 1px solid red !important;
}

.gl-input {
    width: 100%;
    &::-ms-reveal {
        display: none;
    }

    width: 100%;
    font-size: 12px;
    text-overflow: ellipsis;
    line-height: initial;
}

input:disabled,
.input--disable {
    background-color: #f1f1f1 !important;
}

.label-input {
    font-weight: 400;
    line-height: 19px;
    color: #333333;
    float: left;
}

.dropdown-menu-max {
    max-height: 178px;
    overflow-y: auto;
    min-width: unset !important;
    width: 100%;

    &::-webkit-scrollbar-track {
        background-color: white;
    }
}

.gl-disable {
    background-color: #f1f1f1 !important;
}

.gl-input-active,
.gl-border {
    border-color: var(--btn-color) !important;
}

.gl-border-unset {
    border: unset !important;
}

.form-check-input {
    background: red;
}

.fz-14 {
    font-size: 14px;
}
.border_error {
    border-color: red;
}

.form-area {
    border: 1px solid #b1b7c1;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    font-size: 12px;
    color: #333333;
}
.radio-disable{
    &:hover{
        cursor: default !important;
    }
    &:focus{
        background-color: unset !important;
        box-shadow: unset !important;
        outline: unset !important;
        border-color:  rgba(0,0,0,.25) !important;
    }

}

.menu-ellipsis {
    overflow-x: hidden;
    text-overflow: ellipsis;
}


