.content {
    width: 65%;
    margin: auto;
}

.manual__container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.manual__pdf > div {
    border: 1px solid black;
    margin: 10px 0;
}

.manual__pdf {
    width: 100%;
}

.manual__pdf > div > canvas {
    width: 100% !important;
    height: auto !important;
}
