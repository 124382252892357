.container {
    position: relative;
}

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    padding: 20px 30px 15px 30px;
    border: 1px solid #d9dbdf;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    background-color: white;
    &.dashboard {
        padding: 20px 0px 15px 30px;
    }
}

//content
.content {
    width: 100%;
    display: grid;
    padding: 20px 15px;
    gap: 20px;
    background: #f9fafa;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
}

.header-text {
    margin-bottom: 15px;
    &::after {
        content: '';
        position: absolute;
        margin-top: 15px;
        height: 1px;
        width: 100%;
        left: 0;
        background-color: #d9dbdf;
    }
}

.card-title {
    color: #211c61;
    font-size: 25px;
    font-weight: 700;
}
