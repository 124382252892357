.components {
    &__choice {
        min-height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
            &:focus {
                outline: unset !important;
                border: 1px solid var(--btn-color-hover);
            }
            appearance: none;
            -webkit-appearance: none;
            border: 1.33333px solid #999999;
            width: 16px;
            height: 16px;
            &[type='checkbox'] {
                border-radius: 2px;
                &:checked {
                    background: url('~/src/assets/images/icon_checkbox.png') 50% no-repeat !important;
                }
            }
            &[type='radio'] {
                border-radius: 50%;
                &:checked {
                    background: red 50% no-repeat !important;
                    border: 1px solid red;
                }
            }
        }
        // input {
        //     border: 1.33333px solid #999999;
        //     border-radius: 0 !important;
        //     width: 18px;
        //     height: 18px;
        // }
    }
}
