
.appointmentBtn{
    min-width: 0px;
    height: 25px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    white-space: nowrap;
    line-height: 15px;
    text-align: center;
    margin-right: 5px;
    &__approve{
        background-color: #4FA50C;
        color: #FFFFFF;
        width: 34px;
    }
    &__reject{
        background-color: #ef5050;
        color: #FFFFFF;
        width: 34px;
    }
    &__submitted{
        width: 34px;
        border: 1px solid #666666;
    }
    &__detail{
        width: 51px;
        border: 1px solid #666666;
    }
}

.appoint_table_bold {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #333333;
}

