.btn-add-one_time_password {
    width: 200px;
    height: 40px;
    background: #36a40f;
    border: 1px solid #36a40f;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    text-decoration: none;
}

//button
.button-public,
.button-private {
    min-width: 0px;
    height: 25px;
    font-size: 12px;
    font-weight: 400;
    padding: 0 5px;
    border: 1px solid #666666;
    border-radius: 5px;
    white-space: nowrap;
}

.button-public {
    color: #333333;
    &.isActive {
        background: #4fa50c;
        color: var(--white);
        border: 1px solid var(--white);
    }
}

.button-private {
    margin-left: 5px;
    color: #666666;
    &.isActive {
        background: #EF5050;
        color: var(--white);
        border: 1px solid var(--white);
    }
}

.asset-image {
    gap: 2.39px;
    width: 80px;
    height: 60px;
    border-radius: 3.13529px;
    object-fit: contain;
}

.col-action {
    width: 75px;
}