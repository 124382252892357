.image-account {
  border-radius: 5%;
  width: 80%;
  aspect-ratio : 1 / 1;;
  padding-bottom: 80%
}

.text-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #333333;
}


.content {
  padding: 40px;
  gap: 30px;
  max-height: 100%;
  border: 1px solid #D0D0D0;
  height: 100%
}
