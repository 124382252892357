@import '~react-datepicker/dist/react-datepicker.css';
.components {
    &__timepicker {
        .react-datepicker-wrapper {
            width: 100%;
        }
        .react-datepicker-popper {
            z-index: 1002 !important;
            width: calc(100% - 200px);
            .react-datepicker-time__input {
                margin-right: 10px !important;
            }
            input {
                margin-left: 0px !important;
            }
        }
        input {
            font-size: 12px;
            width: 100%;
            height: 40px;
            border: 1px solid #c4c4c4;
            border-radius: 4px;
            outline: none;
            padding: 0.375rem 0.75rem;
            &:focus-visible {
                outline: unset !important;
                border: 1px solid var(--btn-color-hover);
            }
        }
        input[name='time-input'] {
            width: 100% !important;
        }
        &-img {
            position: absolute;
            top: 50%;
            right: 6%;
            transform: translateY(-50%);
            cursor: pointer;
        }
        &-error {
            border-color: red !important;
        }
    }
}

.react-datepicker__triangle {
    left: -7px !important;
}
