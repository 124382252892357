//button(AppointmentManagement)
.button-approve,
.button-reject {
    min-width: 0px;
    height: 25px;
    font-size: 14px;
    font-weight: 400;
    padding: 0 5px;
    border: 1px solid #666666;
    border-radius: 5px;
    white-space: nowrap;
}

.button-approve {
    color: #333333;
    &.isActive {
        background: #4fa50c;
        color: var(--white);
        border: 1px solid var(--white);
    }
}

.button-reject {
    margin-left: 5px;
    color: #666666;
    &.isActive {
        background: #ef5050;
        color: var(--white);
        border: 1px solid var(--white);
    }
}


.search-button {
    min-width: unset!important;
    width: 100%;
    max-width: 200px ;
}

.edit-button {
    width: 38px;
    min-width: unset!important;
}

.edit-button>span {
    font-weight: normal !important;
}

.button-table {
    min-width: unset !important;
}
.button-table span {
    font-weight: normal !important;
}

.positive-button {
    background: #4FA50C !important;
    border: 0px
}

.negative-button {
    background: #EF5050 !important;
    border: 0px
}

.positive-button span, .negative-button span {
    color: #FFFFFF !important;
}

.button-non-min-width {
    min-width: unset !important;
    width: 100%;
}

.button-fw-normal span {
    font-weight: normal !important;
}
.button-warning{
    border: 1px solid red;
    color: red;
    height: 25px;
    font-size: 14px;
    font-weight: 400;
    padding: 0 5px;
    border: 1px solid #666666;
    border-radius: 5px;
    white-space: nowrap;
    min-width: 0;
}


.address-button {
    width: 68px;
    min-width: unset!important;
}
