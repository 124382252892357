.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    padding: 20px 30px 15px 30px;
    border: 1px solid #d9dbdf;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    background-color: white;
}
.modal {
    max-height: 70vh;
}
.header-text {
    margin-bottom: 15px;
    &::after {
        content: '';
        position: absolute;
        margin-top: 15px;
        height: 1px;
        width: calc(100% - var(--container-padding) * 2);
        left: 0;
        margin-left: var(--container-padding);
        background-color: #d9dbdf;
    }
}

.card-title {
    color: #211c61;
    font-size: 25px;
    font-weight: 700;
}

//container
.content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px 15px;
    margin-top: 1.5rem;
    gap: 20px;
    background: #f9fafa;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
}

.label_input {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.button-detail {
  min-width: 0px;
  height: 25px;
  font-size: 14px;
  font-weight: 400;
  padding: 0 5px;
  border: 1px solid #666666;
  border-radius: 5px;
  white-space: nowrap;
}