.header {
    height: 60px;
    background-color: #ffffff;
    border-bottom: 1px solid #d9dbdf;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: calc(100% - var(--width-open-sidebar));
    z-index: 1003;
    &.sidebar--close {
        width: 100%;
    }

    &__btn-sidebar {
        padding-left: 20px;
        padding-right: 20px;
        background-color: transparent;
    }

    &__user-info {
        display: flex;
        margin-right: 20px;
        align-items: center;
        width: 90%;
        justify-content: flex-end;

        & > span {
            color: #333333;
            margin-right: 10px;
            max-width: 30%;
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}
