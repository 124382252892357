.company-image {
    width: 5%;
    height: 30rem;
}
.company-image-edit {
    width: 100px;
}
.content {
    border: 1px solid #d0d0d0;
    padding: 30px;
    gap: 40px;
    width: 100% !important;
}

.container-detail{
    position: relative !important;
    color: #333333 !important;
}

.image-wrapper {
    height: 13rem;
}

.side-image {
    width: 100%;
    max-height: 130px;
    object-fit: contain;
    border-radius: 5px;
}
.none-logo {
    width: 66px;
    height: 51px;
    object-fit: contain;
    border-radius: 5px;
    flex: auto;
}

.logo-container {
    width: 100%;
    max-width: 130px;
    height: 130px;
    position: relative;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-sizing: content-box;
}

.background-none-img{
    background-color: #F7F7F7 !important;   
}

.button-change-photo {
    min-width: unset !important;
    width: 100%;
}

.button-change-photo span {
    font-weight: normal;
}

.btn-upload {
    width: 12%;
    flex: 0 0 auto;
    margin: 0 0.8rem;
}

.change-logo {
    max-width: 130px;
}

.slide {
    margin-top: 10px;
    &_container {
        align-items: center;
        background-color: #f6f6f6;
        display: flex;
        height: 10rem;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0 12px;
    }
    &_button {
        cursor: pointer;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &--disable {
            cursor: default;
        }
    }
    &_list {
        align-items: center;
        display: flex;
        gap: 0 12px;
        height: 100%;
        margin: 0 auto;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
    }
    &_img {
        width: 150px;
        object-fit: cover;
        background: #f7f7f7;
        border-radius: 5px;
        border: 1px solid #a29a9a;
        cursor: pointer;
        scroll-snap-align: start;
        transform-origin: start start;
        transform: scale(1);
        transition: transform 0.5s;
        height: 110px;

        &--active {
            border: 2px solid #4d4790;
        }
    }
    &_img_edit {
        background-color: white;
        position: relative;
        border-radius: 5px;
        border: 1px solid #a29a9a;
        cursor: pointer;
        scroll-snap-align: start;
        transform-origin: start start;
        transform: scale(1);
        transition: transform 0.5s;
        max-height: 80%;
        flex: 0 0 auto;
        width: 150px;
        height: 110px;
        display: flex;
        align-items: center;
        box-sizing: content-box;

        &--active {
            border: 2px solid #4d4790;
        }
    }
}


.img_close {
    position: absolute;
    top: -10px;
    right: -8px;
    cursor: pointer;
}

.img_edit {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 3px;
}

.text-label {
    color: #333333;
}

.gl-border-bottom-0 {
    border-bottom: unset;
}

.gl-border-top-0 {
    border-top: unset;
}

.gl-object-fit-contain {
    object-fit: contain;
}

.gl-object-fit-cover {
    object-fit: cover;
}
.text-area{
    min-height: 100px !important;
    outline-color: #4D4790 !important;
  }