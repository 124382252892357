.image-content {
    padding: 36.6px;
    gap: 6px;
    width: 100%;
    background: #f7f7f7;
    aspect-ratio: 1 / 1;
    border-radius: 10.375px;
    max-width: 150px;
}

.button-content {
    width: 100%;
    //padding: 11px 10px 10px;
    gap: 10px;
    height: 40px;
    max-width: 150px;
    min-width: unset !important;
    background: #ffffff;
    border: 1px solid #333333;
    border-radius: 5px;
}

.pop-up-add-keybox {
    width: 40% !important;
    max-width: 450px !important;
}

.content-width {
    width: 45% !important;
    max-width: 600px !important;
}
.w_modal_add_room {
    width: 1200px !important;
    min-width: 1200px !important;
    height: 593px;
}

.modal-delete-width {
    height: 0;
    max-height: 700px;
    overflow: hidden;
    width: 400px !important;
    max-width: 450px !important;
}

.custom-checkbox {
    padding-left: -20px;
}

.text-content {
    white-space: pre-line;
}

.text-bold {
    font-weight: 700;
}
.button-form-edit-room {
    margin-top: 38px;
    min-width: 100px !important;
    width: 130px;
    margin-left: 10px;
    color: red;
    border-color: red !important;
}
.warning {
    
    border-color: red !important;
    background-color: #fff3f3 !important;
}
.modal__body--border {
    width: 95%;
    height: 100%;
    padding: 24px;
    border: 1px solid #dee2e6;
    border-radius: 8px;
}

.center-body {
    display: flex;
    justify-content: center;
}
.btn-positive {
    min-width: 120px !important;
    width: 120px !important;
    height: 40px !important;
}

.company-image {
    width: 353px;
    height: 265px;
}
.company-image-edit {
    width: 100px;
}
.content {
    border: 1px solid #d0d0d0;
    padding: 30px;
    gap: 40px;
    width: 100% !important;
}

.button-change-photo {
    min-width: unset !important;
    width: 100%;
}

.button-change-photo span {
    font-weight: normal;
}

.img-upload {
    margin-right: 6px;
    width: 24px;
    height: 24px;
}
.img-upload-asset {
    object-fit: contain;
    margin-top: 48px;
    width: 200px;
    height: 145px;
}

.slide {
    padding: 0;
    &_container {
        margin-top: 15px;
        display: flex;
        align-items: center;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        height: 100px;
        background-color: #f6f6f6;
    }
    &_button {
        cursor: pointer;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &--disable {
            cursor: default;
        }
    }
    &_list {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 12px;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
    }
    &_img {
        width: 150px;
        object-fit: cover;
        background: #f7f7f7;
        border-radius: 5px;
        border: 1px solid #a29a9a;
        cursor: pointer;

        scroll-snap-align: start;
        transform-origin: start start;
        transform: scale(1);
        transition: transform 0.5s;
        height: 110px;

        &--active {
            border: 2px solid #4d4790;
        }
    }
    &_img_edit {
        background-color: white;
        position: relative;
        border-radius: 5px;
        border: 1px solid #a29a9a;
        cursor: pointer;
        scroll-snap-align: start;
        transform-origin: start start;
        transform: scale(1);
        transition: transform 0.5s;
        max-height: 80%;
        flex: 0 0 auto;
        width: 90px;
        height: 67px;
        display: flex;
        align-items: center;
        box-sizing: content-box;

        &--active {
            border: 2px solid #4d4790;
        }
    }
}

.img_close {
    position: absolute;
    top: -10px;
    right: -8px;
    cursor: pointer;
    // width: 20px;

    // &:hover {
    //     background-color: #f6f6f6;
    //     border-radius: 6px;
    // }
}

.img_close_asset {
    position: absolute;
    top: 40px;
    right: 0px;
    cursor: pointer;
    &-error {
        position: absolute;
        right: 64px;
        cursor: pointer;
        top: 156px;
    }
}

.img_edit {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 3px;
}

.gl-object-fit-contain {
    object-fit: contain;
}

.gl-object-fit-cover {
    object-fit: cover;
}
.body_modal_add_room {
    // height: 463px;
    margin: 0 40px !important;
    padding: 20px;
    border: 1px solid #d0d0d0;
}
.border_img {
    height: 603px !important;
    border-right: 2px solid #4d4790;
}
.title-header {
    margin-bottom: 25px;
    line-height: 24px;
    margin-top: 24px;
}
.body_add_room {
    padding: 0px !important;
}
.body_edit_asset {
    overflow: hidden;
    padding: 0px !important;
}
.mr-input {
    margin-top: 20px;
}
.text-area {
    margin-right: 10px;
    min-height: 145px !important;
    width: 210px !important;
    outline-color: #4d4790 !important;
}
.modal-fixed {
    max-height: 70vh;
}
.set-scroll {
    padding-left: 0px;
    height: 442px;
}
.modal-image {
    > div {
        transform: none !important;
        > div {
            max-height: unset !important;
            width: 540px !important;
            min-height: 390px !important;
        }
    }
}
.modal-image-body {
    padding: 0 !important;
}
.modal-image-item {
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    object-fit: contain;
    width: 100%;
    height: 265px;
}
.body_modal_edit_asset {
    margin: 0 40px !important;
    padding: 20px;
    border: 1px solid #d0d0d0;
    height: 645px;
}
.opacity-modal {
    opacity: 0.5 !important;
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #000;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg);
}
.btn-address-edit-asset {
    margin-top: 28px;
}
.opacity-modal {
    opacity: 0.5 !important;
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #000;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg);
}

.storage-input {
    height: 265px;
    width: 300px;
    padding: 11px 10px 10px 10px;
    gap: 10px;
    resize: none !important;
}

.content-storage {
    height: 300px;
    overflow-y: auto;
}
.height_form_input {
    height: 624px;
}
.btnCancel {
    background-color: #4d4790;
    color: #ffffff;
}
@media only screen and (max-width: 1366px) {
    .body_modal_edit_asset {
        margin: 0 40px !important;
        padding: 20px;
        border: 1px solid #d0d0d0;
        height: 463px !important;
    }
    .border_img {
        height: 424px !important;
    }
    .height_form_input {
        overflow: auto !important;
        height: 434px !important;
    }
}

