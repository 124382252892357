.calender {
  border: 1px solid #c0c0c0;
  border-radius: 8px;
}
.rdrMonth {
  width: 100% !important;
}
.rdrDayNumber span {
  color: #333;
}
.rdrMonthAndYearPickers {
  font-size: 14px;
}

.end_date .rdrDayNumber span {
  color: #F36A6A !important;
}

.week__days {
  background-color: #D7F4CD;
  & span {
    color: #333;
    font-weight: bold !important;
  }

  & span:first-child, & span:last-child {
    color: #F36A6A 
  }
}

.today .rdrDayNumber span {
  color: #62D0A2;

  &::after {
    background: #62D0A2;
  }
}

.day__selected {
  background-color: orange;
}