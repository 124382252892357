.button-navigate {
    border: 1px solid #333333;
    border-radius: 0;
    height: 40px;
    width: 100% !important;
    min-width: unset;
}

.button-navigate span {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #333333;
}

.active-button {
    background: #4d4790 !important;
    border: 1px solid #4d4790 !important;
}

.active-button span {
    color: #ffffff;
}

.content {
    border: 1px solid #d0d0d0;
    padding: 40px;
    gap: 40px;
    width: 100% !important;
}
.button-container {
    padding-left: 30px !important;
}

.gl-border-bottom-0 {
    border-bottom: unset;
}

.btn-gl-focus-has:focus-visible ~ .btn-gl-focus {
    border-top: unset !important;
}
