.form_container {
    width: 100%;
    display: grid;
    padding: 20px 15px;
    gap: 20px;
    background: #f9fafa;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
}
.btn-delete {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    height: 38px;
    background: #ff0000;
    border-radius: 5px;
    width: 125px;
}
.btn-search {
    width: 125px;
    height: 38px;
}
.gl-gap-10 {
    gap: 10px;
}
