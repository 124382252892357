.content {
    padding: 20px;
    gap: 30px;
    border: 1px solid #d0d0d0;
}

.image-account {
    object-fit: contain;
    width: 250px;
    height: 250px !important;
}

.text-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}
.btnUser {
    color: #333333;
    background: #f1f1f1;
    width: 200px;
    min-height: 44px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    padding: 10px 20px;
}
.btnActive {
    background: #211c61;
    color: #ffffff;
}

.boxBtn {
    border-bottom: 1px solid #999999;
    margin-bottom: 20px;
}
.btn_reset {
    color: #FFFFFF;
    width: 170px;
    height: 38px;
    background: #ff0000;
    border-radius: 5px;
}
.responsive_img_detail{
    margin-right: 20px;
}

.btnCancel{
    border: none;
    background-color: #4d4790;
    color: #FFFFFF;
  }
