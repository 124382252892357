.pages {
    &__notFound {
        &-container {
            height: 100vh;
            margin-top: 50px;
            padding: 50px 20px 29px 20px;
            background-color: #EBEDEF;
        }
        &-noAuthWrapper {
            height: 100vh;
            background-color: #EBEDEF;
        }
        &-wrapper {
            height: 100%;
            border-radius: 10px;
            background-color: #fff;
        }
        &-title {
            font-size: 40px;
            font-weight: 700;
            line-height: 48.41px;
            color: #333333;
        }
        &-message {
            font-size: 20px;
            font-weight: 400;
            line-height: 50px;
            color: #333333;
        }
    }
}

.wrapper {
    margin-left: var(--width-open-sidebar);
    transition: margin-left 0.3s ease-in-out;
    flex: 1;
    overflow: hidden;

    &.sidebar--close {
        margin-left: var(--width-close-sidebar);
    }
}
