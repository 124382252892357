.login {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    justify-content: center;
    .logo-image {
        width: 139px;
        height: 63px;
    }
}

.login-width {
    width: 450px;
}

.login-wrap {
    background: #fff;
    border-radius: 10px;
    padding: 40px;
    position: relative;

    margin-top: 37px;
    .login-form {
        margin-top: 25px;
    }
}

.form {
    width: 100%;
    display: block;
    flex-direction: column;
    justify-content: space-evenly;
    padding-bottom: 1rem;
    p {
        text-align: start;
    }
}

.form-group-login {
    margin-top: 15px;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
}

.forgot {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-top: 0.8rem;
    text-decoration-line: underline;
    justify-content: start;
    align-self: stretch;
    color: #0376c9;
}

.error-msg {
    margin-bottom: 20px !important;
    font-size: 14px !important;
    line-height: 23px;
    margin-top: 20px;
    float: left;
    margin-bottom: 10px;
    .text-overflow {
        color: #ff0000 !important;
    }
    .error {
        color: #ff0000 !important;
    }
}
.img-error {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.label-login {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #333333;
    font-style: normal;
}
.label-input {
    width: 100%;
    text-align: justify;
    margin-bottom: 10px !important;
    font-weight: 400;
    line-height: 19px;
    color: #333333;
}

.form-input {
    width: 100%;
    display: grid;
    margin-top: 15px;
}
