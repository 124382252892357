@import '~react-datepicker/dist/react-datepicker.css';
.components {
    &__datepicker {
        .react-datepicker-popper {
            z-index: 1002 !important;
        }
        .react-datepicker-wrapper {
            width: 100%;
        }
        &.hide__year--dropdown .react-datepicker__current-month,
        .react-datepicker-time__header,
        .hide__year--dropdown.react-datepicker-year-header {
            display: none !important;
        }
        &.show__year--dropdown .react-datepicker .react-datepicker__navigation {
            top: 9px !important;
        }
        input {
            font-size: 12px;
            width: 100%;
            height: 40px;
            border: 1px solid #dfdfdf;
            border-radius: 0.375rem;
            outline: none;
            padding: 0.375rem 0.75rem;
            &:focus-visible {
                outline: unset !important;
                border: 1px solid var(--btn-color-hover);
                cursor: none;
            }
        }
        input[name='time-input'] {
            width: 100% !important;
        }
        &-img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 17px;
            cursor: pointer;
            width: 20px;
            height: 20px;
        }
        &-error {
            border-color: red !important;
        }
    }
    &__dropdown {
        display: flex;
        justify-content: center;
        align-items: center;
        .dropdown-menu {
            padding: 0;
            border: none;
        }
        .dropdown {
            position: static !important;
        }
    }
}

.react-datepicker__header__dropdown {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    .react-datepicker__month-dropdown-container {
        margin: 0 5px;
    }
    .react-datepicker__year-dropdown-container {
        margin: 0 5px;
    }
}
