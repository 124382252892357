.payment {
    &__card {
        border-radius: 5px;
        border: 1px solid #e2e2e2;
        background: #f9fafa;
        display: flex;
        justify-content: space-around;
        gap: 20px;
        padding: 20px;
        min-height: 156px;
    }
    &__title {
        color: #333;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 16px;
    }
    &__dots {
        align-self: flex-end;
        cursor: pointer;
    }
}

.card__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #211c61;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 15px;
    color: #111;
    flex: 1;
}

.card__max {
    min-width: 224px;
    max-width: 224px;
}

.border__green {
    border-color: #4fa50c;
}
.border__red {
    border-color: #ff0000;
}

.card__title {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}
.card__value {
    color: #111;
    font-size: 24px;
    font-weight: 700;
}

.title__child {
    font-weight: 100;
    font-size: 16px;
}

.list__image {
    display: flex;
    gap: 6px;
}

.payment__slat {
    border-right: 1px solid #e2e2e2;
    width: 1px;
}

.btn {
    width: 58;
    height: 25px;
    text-align: center;
    font-size: 12px;
    border-radius: 5px;

    border: 1px solid #4FA50C;
    background-color: #4fa50c;
    color: #ffffff;
    outline: none;

    &:disabled {
        opacity: 0.5;
    }
}
