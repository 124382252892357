//common
.text-center {
    text-align: center;
}

.text-start {
    text-align: start;
}

.text-nowrap {
    white-space: nowrap;
}

.text-danger {
   color:  #FF0000;
}

.title-text {
    font-weight: 700;
    color: var(--color-title);

    .fs-20 {
        font-size: 20px;
        line-height: 24px;
    }

    .fs-25 {
        font-size: 25px;
        line-height: 30px;
    }
}

.w-49pc {
    width: 49% !important;
}

//flex box
.flexColum {
    flex-direction: column;
}

.flexRow {
    flex-direction: row;
}

.alignItemsCenter {
    align-items: center;
}

.alignItemsEnd {
    align-items: flex-end;
}

.alignSelfCenter {
    align-self: center;
}

.alignSelfStart {
    align-self: flex-start;
}

.alignSelfEnd {
    align-self: flex-end;
}

.justifyBetween {
    justify-content: space-between;
}

.justifyCenter {
    justify-content: center;
}

.justifyAround {
    justify-content: space-around;
}

.justifyStart {
    justify-content: flex-start;
}

.justifyEnd {
    justify-content: flex-end;
}

.flexColumnReverse {
    flex-direction: column-reverse;
}

//font-size
.font-size-12 {
    font-size: 12px !important;
}

.font-size-13 {
    font-size: 13px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-17 {
    font-size: 17px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-19 {
    font-size: 19px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-21 {
    font-size: 21px;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-23 {
    font-size: 23px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-30 {
    font-size: 30px;
}

.fz-1rem {
    font-size: 1rem;
}

.fz-0_8rem {
    font-size: 0.8rem;
}

//font-weight
.font-weight-light {
    font-weight: 300;
}

.font-weight-regular {
    font-weight: 400;
}

.font-weight-bold {
    font-weight: 700;
}

//width
.width-30-pc {
    width: 30% !important;
}

.width-80-pc {
    width: 80% !important;
}

.text-right {
    text-align: right !important;
}

.calendar-image {
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
    line-height: 1;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;
    width: 20px;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px 15px;
    margin-top: 1.5rem;
    gap: 20px;
    background: #f9fafa;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
}

.container-asset {
    position: relative;
}

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    padding: 20px 30px 15px 30px;
    border: 1px solid #d9dbdf;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    background-color: white;
}

.header-text {
    margin-bottom: 15px;

    &::after {
        content: '';
        position: absolute;
        margin-top: 15px;
        height: 1px;
        width: calc(100% - var(--container-padding) * 2);
        left: 0;
        margin-left: var(--container-padding);
        background-color: #d9dbdf;
    }
}

.card-title {
    color: #211c61;
    font-size: 25px;
    font-weight: 700;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px 15px;
    margin-top: 1.5rem;
    gap: 20px;
    background: #f9fafa;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
}

.text-purple {
    color: #fa00ff !important;
}

.text-blue {
    color: #2400ff !important;
}

.text-green {
    color: #51a60e;
}

.text-red {
    color: #ff0000;
}

.text-gray {
    color: #999999;
}

.label-color {
    color: var(--color-label);
}

.black-color {
    color: var(--black);
}

// width classes
.width-33 {
    width: 33px !important;
}

.width-40 {
    width: 40px !important;
}

.width-84 {
    width: 84px !important;
}

.width-170 {
    width: 170px !important;
}

.width-fill-available {
    width: -webkit-fill-available;
}

.error-msg {
    margin-top: 5px;
    float: left;

    .text-overflow {
        white-space: pre-line;
        color: #ff0000 !important;
    }

    .error {
        color: #ff0000 !important;
    }
}

.block {
    display: block;
}

.display-none {
    display: none !important;
}

button {
    &:focus-visible {
        border: 2px solid var(--btn-color-hover);
    }
}

.label_asterisk::after {
    content: '*';
    color: #FF0000;
}

.gl-radio:checked {
    background-color: unset !important;
    border: unset !important;
    background-image: url('~/src/assets/images/radio.png') !important;
}

.gl-radio {
    margin-top: 0 !important;
    &:hover {
        cursor: pointer;
    }
}

.btn-remove {
    width: 90px;
    border: 1px solid #ff0000;
    color: #ff0000;
}
.text_color_green {
    color: #51a60e !important;
}
.text_color_red {
    color: #ff0000 !important;
}
.text_color_blue {
    color: #0376C9 !important;
}
.text_color_blue_bold {
    font-weight:bold !important;
    color: #0376C9 !important;
}

.size_checkbox {
    width: 27px !important;
    height: 27px !important;
    display: flex !important;
    text-align: center !important;
}
.size_radio--20{
    width: 20px !important;
    height: 20px !important;
    display: flex !important;
    text-align: center !important;
    margin-right: 2px;
}
input {
    &[type='checkbox'] {
        width: 16px;
        height: 16px;
        border: 1.33333px solid #999999;
        border-radius: 2px;
        &:checked {
            background: url('~/src/assets/images/icon_checkbox.png') 50% no-repeat !important;
            border-color: unset !important;
        }
    }
    &[type='checkbox'][readonly] {
        pointer-events: none;
    }
}
.size_checkbox--18{
    width: 18px !important;
    height: 18px !important;
    border-radius: 0px !important;
    margin-top: 2px !important;
    margin-right: 10px;
}
.text-color-blue-custom{
    color: #001AFF;
}
.set_no_ellipsis{
    text-overflow: unset !important;
}

.height-500{
    height: 500px !important;
}

.padding-bottom0{
    padding-bottom: 0px !important;
}

.button-detail {
    min-width: 0px;
    max-width: 60px;
    height: 25px;
    font-size: 12px;
    font-weight: 400;
    padding: 0 5px;
    border: 1px solid #666666;
    border-radius: 5px;
    white-space: nowrap;
    line-height: 15px;
}
.m-b05r{
    margin-bottom: .5rem;
}
