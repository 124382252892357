//container
.content-wrapper {
    min-width: 45vw;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    align-items: flex-start;
}

.content {
    gap: 20px;
}

.no-img {
    width: 250px;
    height: 250px;
    display: grid;
    justify-items: center;
    align-items: center;
    background: #f7f7f7;
    border-radius: 17.2917px;
}

// .calendar-height {
//     height: 400px;
// }

.w-47 {
    width: 47%;
}
.w-6 {
    width: 6%;
}

.border-calendar {
    border: 1px solid #c0c0c0;
    border-radius: 8px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-height: 444px;
    overflow-y: auto;
    color: #333;
}
.weekday {
    color: #f36a6a;
}
.today {
    background: none;
    color: #62d0a2;
    position: relative;
}

.today::after {
    content: '・';
    position: absolute;
    top: 0;
    right: 0;
    font-weight: bold;
    color: #62d0a2;
}

.schedule {
    width: 100%;
    background-color: #5865f2;
    border-radius: 8px;
    color: white;
    padding: 10px;
    &__title {
        font-weight: bold;
    }
}
