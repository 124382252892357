.content-popup {
    text-align: center;
    color: #333333;
}
.button {
    :hover {
        background: none !important;
    }
    border: 1px solid black;
    border-radius: 5px;
    width: 170px !important;
    min-width: 140px !important;
    height: 40px;
    color: #333333 !important;
    background: #ffffff !important;
}
.modal {
    width: 450px !important;
}
