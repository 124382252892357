//container
.content-wrapper {
    min-width: 45vw;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    align-items: flex-start;
}

.content {
    gap: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    color: #333333;
}

.no-img {
    width: 250px;
    height: 250px;
    display: grid;
    justify-items: center;
    align-items: center;
    background: #f7f7f7;
    border-radius: 17.2917px;
}
