.asset-image {
    gap: 2.39px;
    width: 80px;
    height: 60px;
    border-radius: 3.13529px;
    object-fit: contain;
}
.button-detail {
    min-width: 0;
    height: 25px;
    font-size: 12px;
    font-weight: 400;
    padding: 0 5px;
    border: 1px solid #666666;
    border-radius: 5px;
    white-space: nowrap;
}

.col-action {
    width: 75px;
}
.public {
    width: 90px;
    color: #51a60e;
    border: 1px solid #333333;
    border-radius: 5px;
}
.private {
    width: 90px;
    color: #ff0000;
    border: 1px solid #333333;
    border-radius: 5px;
}
.delete-item {
    width: 90px;
    color: #ffffff;
    border: none;
    background-color: #ff0000;
}
.btn-add {
    width: 170px;
}
.label-btn-add {
    font-size: 14px !important;
}
.btn-search {
    margin-right: 5px !important;
    float: right;
    width: 125px;
}
.btn-delete {
    margin-right: 15px !important;
    float: right;
    width: 125px;
}
.label-btn-detail {
    margin-top: 3px;
}
.content-popup {
    // text-align: center;
    color: #333333;
}
.text_public {
    color: #51a60e;
}
.modal {
    width: 400px !important;
}
.text-600 {
    font-weight: 600;
}
.text_private {
    color: #ff0000;
}
.set_no_ellipsis {
    text-overflow: unset !important;
}
.set_checkbox {
    margin-top: 2px !important;
    width: 18px !important;
    height: 18px !important;
}
.table {
    color: #333333 !important;
}
.buttonModal{
    &:hover {
        color: #fff;
        background-color: var(--btn-color-hover);
        border-color: var(--btn-color-hover);
    }
    font-size: 14px;
    width: 170px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: #4d4790;
    border: 1px solid #4d4790;
    color: var(--white);
    border-radius: 5px;
}
.btn_cancel{
    color: #000000;
}

.asset-description{
    display: inline-flex;
    padding: 10px 0 0 12px;
    &_text{
        color: #fa1a02;
        font-weight: bold;
        padding-left: 5px;
    }
}
