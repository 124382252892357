.container-drawer {
    position: fixed;
    height: 100vh;
    background: linear-gradient(180deg, #110B54 0%, #4D4790 100%);
    transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
    z-index: 1;
    &.open-sidebar {
        overflow-y: auto;
        overflow-x: hidden;
        width: var(--width-open-sidebar);
        .logo-navigation {
            border-bottom: 1px solid rgb(255,255,255, 0.2);
        }
        .logo-navigation img {
            width: 69px;
        }
    }
    &.close-sidebar {
        width: var(--width-close-sidebar);
        .logo-navigation {
            padding-left: 10px;
        }
        .logo-navigation img {
            width: 56px;
        }
        .text-logo,
        .text-item,
        .item-dropdown-active,
        .item-dropdown-no-active {
            display: none;
        }
        .list-item:hover {
            background: #110b54;
            width: var(--width-open-sidebar);
            .text-item {
                display: unset !important;
            }
            .item-dropdown-active,
            .item-dropdown-no-active {
                display: flex !important;
            }
        }
    }
}

.logo-navigation {
    display: flex;
    justify-content: center;
    gap: 20px;
    background-color: white;
    height: 59px;
    align-items: center;
    cursor: pointer;
    img {
        transition: width 0.3s ease-in-out;
    }
}
.logo-image {
    opacity: 1 !important;
}
.text-logo {
    margin-left: 20px;
    white-space: nowrap;
    transition: -webkit-transform ease-in-out 0.3s;
    transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
}
.text-top {
    font-weight: 700;
    line-height: 19px;
    color: #ffffff;
}
.text-bottom {
    font-weight: 400;
    font-size: 12px;
    opacity: 0.3;
    color: #ffffff;
    line-height: 15px;
}
.active {
    z-index: 10;
    border-left: 5px solid #9b94e8;
    padding-left: 15px;
    height: 49px;
    display: flex;
    text-decoration: none;
    align-items: center;
    background: linear-gradient(119deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.2)), rgba(54, 50, 101, 0.75);
}
.text-item {
    margin-left: 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    position: absolute;
    width: calc(var(--width-open-sidebar) - 30px);
    color: #ffffff;
}
.no-active {
    z-index: 10;
    height: 49px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    opacity: 0.7;
    text-decoration: none;
}
.item-dropdown-active {
    z-index: 10;
    height: 49px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), rgba(77, 71, 144, 0.5);
    text-decoration: none;
    z-index: 10;
}
.item-dropdown-no-active {
    height: 49px;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding-left: 20px;
    opacity: 0.7;
    z-index: 10;
}

//mutlimenu level 3
.dropdown .dropdown {
    .active {
        padding-left: 35px !important;
    }
    .no-active {
        padding-left: 40px;
    }
}
.dropdown-list-item{
    .text-item{
        font-weight: 400 !important;
    }
    .active{
        .text-item{
            font-weight: 400 !important;
        }
    }
}
.wrapper{
    &:hover{
        cursor: pointer;
    }
    border-bottom: 1px solid rgb(255,255,255, 0.2);
}
