.container-asset {
    position: relative;
    width: 100%;
    color: #333333;
}

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    padding: 20px 30px 15px 30px;
    border: 1px solid #d9dbdf;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    background-color: white;
}

.header-text {
    margin-bottom: 15px;
    &::after {
        content: '';
        position: absolute;
        margin-top: 15px;
        height: 1px;
        width: calc(100% - var(--container-padding) * 2);
        left: 0;
        margin-left: var(--container-padding);
        background-color: #d9dbdf;
    }
}

.card-title {
    color: #211c61;
    font-size: 25px;
    font-weight: 700;
}
.detail-asset {
    width: 60%;
    height: 100%;
}
.info-asset {
    width: 100%;
    height: 246px;
    border: 1px solid #d0d0d0;
    border-radius: 10px;
    margin-top: 30px;
    display: flex;
}
.logo-image {
    margin: 30px;
    width: 135px;
    height: 135px;
    background: #f7f7f7;
    border-radius: 9.3375px;
}
.info-asset-text {
    margin-left: 10px;
    display: block;
    margin-top: 30px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}
.info-asset-btn {
    display: flex;
}
.info-asset-btn-delete {
    min-width: 131px;
    width: 131px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #333333;
    border-radius: 5px;
    margin-right: 15px;
    font-family: 'Inter';
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #333333;
}
.info-asset-btn-edit {
    min-width: 131px;
    width: 131px;
    height: 40px;
    background: #4d4790;
    border: 1px solid #4d4790;
    border-radius: 5px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    font-family: 'Inter';
}
.info-asset-text-detail {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 25px;
    line-height: 24px;
    color: #000000;
    font-family: 'Inter';
    font-style: normal;
}
.info-asset-text-detail1 {
    margin-bottom: 10px;
}
.title_table {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.info-asset-btn-add {
    width: 150px;
    height: 35px;
    color: #ffffff;
    background: #36a40f;
    border: 1px solid #36a40f;
    border-radius: 5px;
}
.table-btn-edit {
    min-width: 38px !important;
    width: 38px;
    height: 27px;
    background: #ffffff;
    border: 1px solid #666666;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #333333;
}
.table-btn-delete {
    min-width: 38px !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: 38px;
    height: 27px;
    border: 1px solid #666666;
    border-radius: 5px;
}
.history-asset-detail {
    margin: 30px 0 0 20px;
    width: 40%;
}

.content {
    border: 1px solid #d0d0d0;
    border-radius: 10px;
    padding: 30px;
    .content__carousel {
        width: 353px;
    }
}

.button {
    font-size: 14px !important;
    &__content {
        min-width: unset;
        width: 125px !important;
        display: inline-flex !important;
    }
    &__delete {
        min-width: unset;
        width: 125px !important;
        color: #ffffff !important;
        border: none;
        background-color: #ff0000;
    }
    &__add {
        font-size: 14px;
        width: 170px;
        height: 40px;
    }
}

.side-content {
    border-radius: 10px;
}

.room-image {
    padding: 32.94px;
    gap: 5.4px;
    width: 134.02px;
    height: 134.02px;
    background: #f7f7f7;
    border-radius: 9.3375px;
}

.title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.section-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: #333333;
}

.button-public {
    min-width: 0px;
    height: 25px;
    font-size: 14px;
    font-weight: 400;
    padding: 0 5px;
    border: 1px solid #666666;
    border-radius: 5px;
    white-space: nowrap;
}

.button-detail {
    &__active {
        background-color: #6761b1;
        color: white;
        height: 25px;
        font-size: 12px;
    }
    min-width: 0px;
    height: 25px;
    font-size: 12px;
    font-weight: 400;
    padding: 0 5px;
    border: 1px solid #666666;
    border-radius: 5px;
    white-space: nowrap;
}
.text-green {
    color: #0376c9 !important;
}
.list-room {
    border-right: 2px solid #4d4790;
}
.asset-image {
    object-fit: contain;
    max-width: 300px;
    height: 265px;
}
.span {
    height: 0px !important;
    width: 0px !important;
}
.count-image {
    left: 318px;
    z-index: 1;
    top: 255px;
    text-align: center;
    width: 34px;
    height: 22px;
    position: absolute;
    background: #ffffff;
    border-radius: 5px;
}
.count-image-room {
    margin-top: 200px;
    margin-left: 295px;
    position: absolute;
    text-align: center;
    width: 34px;
    height: 22px;
    z-index: 1;
    background: #ffffff;
    border-radius: 5px;
}