.content {
    width: 65%;
    margin: auto;
}

.term {
    p {
        padding: 0;
        margin: 0;
        color: #333333;
        word-break: break-all;
    }
    &-background {
        background-color: #93939363;
    }
    &-bd-bottom {
        border-bottom: 1px solid black;
    }
    &-list-1 {
        list-style: decimal;
        margin-bottom: 0;
        word-break: break-all;
    }
    &-list-2 {
        list-style: lower-alpha;
    }
    &-list-3 {
        list-style: disc;
    }
    &-link {
        color: blue !important;
    }
}
