//table
.custom-table {
    width: 100%;

    table thead,
    table > tbody > tr:nth-child(even),
    table > tbody > tr:nth-child(even) td,
    table > tbody:nth-child(odd) {
        background-color: #f0f0f0;
    }

    table {
        table-layout: fixed;
    }

    .date-time {
        width: 150px !important;
    }
}

.hidden-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header-background {
    background: #F0F0F0 !important;
}
