//container
.container-fluid {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.login-page {
    width: 100%;
    min-height: 100vh;
    background: var(--background-color);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .form-login {
        width: 100%;
    }
}

.logo {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    justify-content: center;
    .logo-image {
        width: 139px;
        height: 63px;
    }
}

.footer {
    text-align: center !important;
    font-size: 14px;
    line-height: 17px;
    margin-top: 30px;
    a,
    span {
         
        color: #999999;
    }
}

