.pagination {
  position: relative;

  &_total {
    position: absolute;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #001AFF;
    top: 0;
    bottom: 0;
    left: 0;
    display: inline-flex;
    align-items: center;
  }


  &_content {
    display: flex;
    justify-content: center;
    gap: 10px;

    >span {
      display: inline-flex;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: #26292f;
      border: 1px solid #666666;
      border-radius: 5px;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
        cursor: unset;
      }

      &.current {
        color: #fff !important;
        background-color: #4d4790;
        cursor: unset !important;
      }
    }
  }
}
