.button-detail {
    min-width: 0px;
    max-width: 60px;
    height: 25px;
    font-size: 12px;
    font-weight: 400;
    padding: 0 5px;
    border: 1px solid #333333;
    border-radius: 5px;
    white-space: nowrap;
    color: #333333;
}

.col-action {
    width: 75px;
}
.asset-image {
    gap: 2.39px;
    object-fit: contain;
    width: 80px;
    height: 60px;
    border-radius: 4.13459px;
}

.broker-name {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}
