.table-container {
    width: 100%;
    font-size: 12px;
    color: var(--color-label);
    
    table thead,
    table > tbody > tr:nth-child(even),
    table > tbody > tr:nth-child(even) td,
    table > tbody:nth-child(odd) {
        background-color: #f0f0f0;
    }

    table {
        color: #333333 !important;
        table-layout: fixed;
    }

    table > thead > tr,
    table > tbody > tr {
        vertical-align: middle;
    }

    table > tbody > tr > td {
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
