.section-resetpwd {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    justify-content: center;
}

.form-resetpwd {
    background: #fff;
    width: 450px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    padding: 40px;
    position: relative;
    margin-top: 37px;
    .login-form {
        margin-top: 25px;
        font-size: 14px;
    }
}
.title-reset_password {
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;

    /* #333333 */

    color: #333333;
}
.content-popup {
    text-align: center;
    color: #333333;
}
.buttonModal {
    &:hover {
        background: var(--btn-color-hover) !important;
    }
    width: 120px !important;
    min-width: 120px !important;
    height: 40px;
    color: #ffffff;
    background: linear-gradient(0deg, #4d4790, #4d4790) !important;
}

.brick {
    border-bottom: 1px solid #d9d9d9;
}
